import Turbolinks from 'turbolinks'
// Tell Webpacker to add the scss file
import '../scss/application.scss'
//Pack images as well
require.context('../images', true)
// Import JS to do the fixed-header-height class
import 'lightgallery.js/dist/css/lightgallery.min.css'
// Sweet Scroll
import SweetScroll from 'sweet-scroll';
//Stimulus JS
import 'controllers'
import assignHeights from '../custom/assign_heights'
// Animate on Scroll
import AOS from 'aos'
import 'aos/dist/aos.css'
// Start Turbolinks
Turbolinks.start()

document.addEventListener('turbolinks:load', function () {
  new SweetScroll()
  AOS.init({
    duration: 500
  });
  assignHeights();
})