import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['overlay']

  toggle() {
    this.overlayTarget.classList.toggle('overlay-active')
  }

  toggleBanner() {
    console.log('toggle Banner')
    document.querySelector('.banner-message').classList.toggle('overlay-open')
  }
}
