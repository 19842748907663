import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import RevealController from 'stimulus-reveal'
import Lightbox from 'stimulus-lightbox'
import 'alpinejs'

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.register('reveal', RevealController)
application.register('lightbox', Lightbox)
application.load(definitionsFromContext(context))