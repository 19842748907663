import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['faqBox']

  connect() {}

  faqSlider(event) {
    let symbol = event.srcElement.querySelector('svg')
    let parentBox = event.srcElement.parentNode
    let contentBox = parentBox.querySelector('[data-faq-content]')
    symbol.classList.toggle('transform')
    symbol.classList.toggle('rotate-45')
    contentBox.classList.toggle('faq-open')
  }
}
