import { Controller } from 'stimulus'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  static targets = ['studioSlider', 'prevButton', 'nextButton']

  connect() {
    const studioSlider = new Swiper(this.studioSliderTarget, {
      loop: true,
      slidesPerView: 1,
      autoplay: true,
      effect: 'fade',
      fadeEffect: {
        crossfade: true,
      },
      navigation: {
        nextEl: this.nextButtonTarget,
        prevEl: this.prevButtonTarget,
      },
    })
  }
}
