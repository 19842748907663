import { Controller } from 'stimulus'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  static targets = ['eventBox', 'popUp']

  connect() {
    const studioSlider = new Swiper('#mask', {
      loop: true,
      slidesPerView: 1,
      autoplay: true,

      navigation: {
        nextEl: '[data-next-slide]',
        prevEl: '[data-prev-slide]',
      },
    })
  }

  toggleOverlay() {
    this.overlayTarget.classList.toggle('overlay-active')
  }

  deleteSlider(event) {
    event.srcElement.parentElement.classList.toggle('hidden')
  }

  createSlider(event) {}
}
