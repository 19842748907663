import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    document.addEventListener('scroll', () => {
      if (window.pageYOffset > 0) {
        this.element.classList.add('banner-message--hidden')
      } else {
        this.element.classList.remove('banner-message--hidden')
      }
    })
  }

  toggleBanner() {
    this.element.style.minHeight('0px')
  }
}
